@import url('https://fonts.googleapis.com/css2?family=Abel&family=Dosis:wght@200;700&family=Kanit:ital,wght@0,100;0,700;1,300&family=Lobster&family=Orbitron:wght@400;700&family=Play:wght@400;700&family=Raleway:wght@100;300;700&family=Righteous&family=Roboto+Condensed:ital,wght@0,300;0,700;1,300&family=Teko:wght@300;700&family=Titillium+Web:wght@200;300;700&display=swap');
*{
  /* font-family: 'Abel', sans-serif; */
  /* font-family: 'Dosis', sans-serif; */
  /* font-family: 'Kanit', sans-serif; */
  /* font-family: 'Lobster', cursive; */
  /* font-family: 'Orbitron', sans-serif; */
  /* font-family: 'Play', sans-serif; */
  font-family: 'Raleway', sans-serif;
  /* font-family: 'Righteous', cursive; */
  /* font-family: 'Roboto Condensed', sans-serif; */
  /* font-family: 'Teko', sans-serif; */
  /* font-family: 'Titillium Web', sans-serif; */
  margin:0; padding:0;
  box-sizing: border-box;
  outline: none; border:none;
  transition: all .2s linear;
}
body{
  animation: fadeInLeft 1s linear;
  background-color:#f2f2f2;
}
header{
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid gainsboro;
  background-color: rgb(15, 52, 90);
}
.navbar{
  width: 95%;
  /* height: 90%; */
  display: flex;
  border-radius: 1rem;
}
.navbar--left{
  width: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* border: 1px solid coral; */
}
.navbar--logo{
  width: 140px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
h5 , h1 , h2,h3,h4,h6,p{
  text-decoration: none;
  margin-bottom: 0 !important;
}
span{
  color: #f0c14b;
}
.navbar--center{
  width: 60%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* border: 1px solid coral; */
}
.navbar--center--content{
  display: flex;
  align-items: center;
  justify-content: center;
  /* border: 1px solid coral; */
  width: 100%;
}
.navbar--right{
  width: 20%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* border: 1px solid coral; */
}
.navbar--right--content{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.logo--resp{
  display: none;
}
.icon--menu{
  display: none;
}
.icons--menu{
  display: none;
}
.na{
  display: none;
}
.menu {
  position: fixed;
  width: 300px;
  height: 100vh;
  top: 0;
  left: -1000px;
  display:flex;
  align-items: center;
  justify-content: center;
  z-index: 10000;
  transition: ease-in-out 1s;
  box-shadow: 0 0 9px 5px black;
  background-color:#fff;
  padding: 1rem;
  color: #0069ff;
}
.show--menu{
  left: 0;
  transition: ease-in-out 1s;
}
.sidebar--menu{
  width: 100%;
  height: 95%;
  /* text-align: center; */
  /* font-weight: bold; */
}
.sidebar--menu > p{
 padding: 1rem;
}
.btn--close--sidebar{
  width: 100%;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 2rem;
  cursor: pointer;
  margin-bottom: 1rem;
}
.btn--close--sidebar > i{
    font-size: 1.2rem;
    color: #0069ff;
}
.menu__content{
  padding: 1rem;
  width: 100%;
  height: 500px;
}
.menu__content__liste{
  width: 100%;
  margin-bottom: 1rem;
}
.container--header{
  font-size: 2rem;
  color: rgb(12, 52, 103);
  font-style: italic;
  text-align: center;
  width: 100%;
  /* border: 1px solid coral; */
}
.liste{
  text-decoration: none;
  color: white;
  font-size: 1rem;
  margin-left: 1.3rem;
}
.list{
  text-decoration: none;
  color: rgb(12, 52, 103);
  font-size: 1rem;
  margin-left: 1.3rem;
}
.list:hover{
  color: #f9004d;
}
.liste:hover{
  color: #f9004d;
}
.liste > i{
  font-size: 1rem;
}
.header__title{
  color: white;
  font-weight: bold;
  text-decoration: none;
}
.home--part{
  width: 100%;
  height: 100vh;
}
.slogan > p{
  font-size: 2rem;
}
.main--part--container{
  width: 100%;
}
.main--part{
  width: 100%;
  height: 100vh;
  /* display: flex;
  align-items: center;
  justify-content: center; */
  animation: fadeInRight 1s linear;
  font-size: 1rem;
  position: relative;
  background-image: url('../public/doum.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
}
.main--part img{
 width: 100%;
 height: 100%;
}
.description__dev{
  position: absolute;
  width: 810px;
  height: 300px;
  top: 30%;
  left: 27%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* border: 1px solid coral; */
}
.description__dev__left{
  width: 90%;
  color: rgb(12, 52, 103);
  /* border: 1px solid coral; */
}
.description__dev__right{
  width: 10%;
  height: 100%;
  /* border: 1px solid coral; */
}
.dev__right__box{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 33%;
  color: rgb(12, 52, 103);
  /* border: 1px solid coral; */
}
.dev__right__box{
  font-size: 1.5rem;
}
.dev__right__box:hover{
  color: #f9004d;
  cursor: pointer;
}
.nav__first{
  width: 100%;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgb(12, 52, 103);
}
.dev__off{
  font-size: 1.5rem;
  font-weight: bold;
  color: rgb(12, 52, 103);
}
.btn__cv{
  background-color: #f9004d;
  padding: 0.5rem;
  border-radius: 1.1rem;
  color: white;
  /* width: 100px; */
  margin: 1rem 0;

}
span{
  color: #f9004d;
}
.service{
	background-color: #101010;
	width: 100%;
	padding: 100px 0px;
}
.card__content{
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 400px;
}
.card{
	height: 330px;
	width: 335px;
	padding: 1rem;
	border-radius: 20px;
	margin: 15px;
	position: relative;
	overflow: hidden;
	text-align: center;
}
.card i{
	font-size: 50px;
	display: block;
	text-align: center;
	margin: 25px 0px;
	color: #f9004d;
}
.services--header{
  text-align: center;
  font-weight: bold;
  color: #f9004d;
}
.card--title{
  font-weight: bold;
	font-size: 23px;
	margin-bottom: 15px;
}
.card--body p{
	/* color: #fcfc; */
	font-size: 1rem;
	line-height: 27px;
	margin-bottom: 25px;
  font-weight: bold;
}
.card .button{
	background-color:#f9004d;
	color: white;
	text-decoration: none;
	border: 2px solid transparent;
	font-weight: bold;
	padding: 9px 22px;
	border-radius: 30px;
	transition: .4s; 
}
.card .button:hover{
	background-color: transparent;
	border: 2px solid #f9004d;
	cursor: pointer;
}
.main__part__services{
  width: 100%;
  margin: 1rem 0;
  animation:fadeInRight .5s linear;
}
.btn__more{
  padding: 0.7rem;
  border-radius: 0.5rem;
  background-color: #f9004d;
  color: white;
}
.btn__more:hover{
  background-color: #fff;
  color: #f9004d;
}
.description--author{
  width: 100%;
  padding: 0px 5rem;
  display: flex;
  animation:fadeInRight .5s linear;
}
.description--author--content--left{
  width: 60%;
  padding: 1rem;
}
/* .description--author--content--left > p{
  font-weight: bold;
} */
.description--author--content--left > ul li{
  font-weight: bold;
}
.description--author--content--right{
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  padding:2rem;
}
.name--author{
  color: #f9004d;
  font-weight: bold;
  font-size: 2.7rem;
}
.images{
  width: 450px;
  height: 300px;
}
.images img{
  width: 100%;
  height: 100%;
}

.footer{
  width: 100%;
  padding: 10px 170px;
  /* background-color:rgb(12, 52, 103); */
  color: white;
  font-weight: bold;
  background-color: rgb(12, 52, 103);
}
.footer p{
  margin: 1rem;
  color: white;
}
.foot--content{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content:space-around;
  border-top: 5px solid coral;
}
.schedule{
  height: 200px;
  text-align: center;
}
.address{
  height: 200px;
  text-align: center;
}
.social--media{
  height: 200px;
  text-align: center;
  cursor: pointer;
}
.social--media i{
  padding-top: 0.3rem ;
  padding-right: 0.3rem;
  font-size: 1.3rem;
}
.name--developer{
  width: 100%;
  text-align: end;
}
.right{
  width: 100%;
  text-align: center;
}
.title{
  color: #000;
  font-size: 2rem;
  font-weight: bold;
}
.main__part__portfolio{
  width: 100%;
  height: 100vh;
  /* background-color: #000; */
  /* height: 100vh; */
  padding: 0 1rem;
  animation: fadeInRight 1s linear;
}
.last__project{
  font-weight: bold;
  font-size: 2rem;
}
.card__content__block{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
}
.cards{
  width: 270px;
  height: 170px;
  margin: 0.7rem;
  border-radius: 0.7rem;
}
.cards img{
  width: 100%;
  height: 100%;
  border-radius: 0.7rem;
}
.name{
  font-weight: bold;
  font-size: 3rem;
}
.main--contact{
  width: 100%;
  padding: 1rem;
  animation: fadeInRight 1s linear;
  text-align: center;
}
.main--contact--header{
  width: 100%;
  /* text-align: center; */
}
.main--contact--content{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 1rem;
}
.card--contact{
  width: 230px;
  height: 180px;
  /* border: 1px solid coral; */
}
.card--contact--header{
  height: 20%;
  font-size: 1.5rem;
  color: #f9004d;
  margin-bottom: 0.3rem ;
}
.contact--title{
  color: rgb(172, 172, 226);
}
.contact--me{
  font-size: 3rem;
  font-weight: bold;
  color: #f9004d;
}
.container__skills{
  width: 100%;
  /* height: 500px; */
  /* padding: 1rem; */
  /* border: 1px solid coral; */
}
.skills__content{
  width: 100%;
  /* height: 300px; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  /* border: 1px solid coral; */
}
.skills__box{
  width: 80px;
  height: 80px;
  margin: 0.5rem;
  border-radius: 0.5rem;
  /* border: 1px solid coral; */
}
.skills__box img{
  width: 100%;
  height: 90%;
  border-radius: 0.5rem;
}
.link__skills{
  text-align: center;
  text-decoration: none;
  font-weight: bold;
  color:rgb(12, 52, 103);
}
@media only screen and (max-width:576px) {
  header{
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid gainsboro;
    background-color: white;
  }
  .menu__content{
    padding: 0;
    width: 100%;
    height: 500px;
  }
  .navbar--center--content{
    display: none;
  }
  .navbar--left{
    width: 20%;
    display: flex;
    justify-content: flex-start;
  }
  .navbar--center{
    width: 50%;
  }
  .navbar--right{
    width: 30%;
    display: flex;
    justify-content: flex-end;
  }
  .navbar--right--content{
    display: none;
  }
  .na{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    height: 50px;
    padding: 0 0.7rem;
    background-color: #fff;
  }
  .na--title{
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: end;
  }
  .header__bar{
    display: none;
  }
  .icon--menu{
    width: 10%;
    height: 100%;
    font-size: 1.3rem;
    align-items: center;
    display: flex;
    justify-content: center;
    color: rgb(15, 52, 90);
    cursor: pointer;
  }
  /* .icon--menu:nth-child(3){
    width: 10%;
    height: 100%;
    font-size: 1.3rem;
    align-items: center;
    display: flex;
    justify-content: center;
    color: white;
    cursor: pointer;
  } */
  .logo--resp{
    display: flex;
  }
  .navbar--logo{
    display: none;
  }
  .navbar--logo--resp{
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-self: center;
  }
  .navbar--logo--resp img{
    width: 100%;
    height: 100%;
  }
  .header__title{
    color: rgb(12, 52, 103);
    font-weight: bold;
    text-decoration: none;
  }
  .home{
    width: 100%;
    height: auto;
  }
  .home--part{
    width: 100%;
    height: auto;
  }
  .main--part{
    width: 100%;
    height: 30vh;
    position: relative;
    background-image: url('../public/doum.jpg');
    background-position: left;
  }
  .description__dev{
    position: absolute;
    width: 250px;
    height: 100px;
    top: 15%;
    left: 30%;
    display: block;
    font-size: 0.8rem;
    /* border: 1px solid coral; */
  }
  .btn__cv{
    display: none;
  }
  .description__dev__left{
    width: 100%;
    color: rgb(12, 52, 103);
    /* border: 1px solid coral; */
  }
  .description__dev__right{
    /* width: 100%;
    height: 50px; */
    display: none;
    /* align-items: center;
    justify-content: start; */
    /* border: 1px solid coral; */
  }
  .description{
    display: flex;
  }
  .dev__right__box{
    width: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 33%;
    color: rgb(12, 52, 103);
    /* border: 1px solid coral; */
  }
  .dev__right__box{
    font-size: 1.5rem;
  }
  .nav__first{
    display: none;
  }
  .name{
    font-size: 1rem;
  }
  .dev__off{
    font-size: 0.8rem;
  }
  .description--author{
    width: 100%;
    padding: 0;
    display: block;
  }
  .description--author--content--left{
    width: 100%;
    height: auto;
    padding: 1rem;
  }
  .description--author--content--right{
    width: 100%;
    height: auto;
    padding:1rem;
  }
  .images{
    height: 250px;
  }
  .card__content{
    width: 100%;
    flex-wrap: wrap;
    height: auto;
  }
  .card__content__block{
    width: 100%;
    height: auto;
    justify-content: center;
    flex-wrap: wrap;
  }
  .main__part__portfolio{
    width: 100%;
    height: auto;
    padding: 0 1rem;
    animation: fadeInRight .5s linear;
  }
  .last__project{
    font-size: 1rem;
  }
  .main--contact--content{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 1rem;
  }
  .card--contact{
    width: 230px;
    height: 180px;
    /* border: 1px solid coral; */
  }
  .slogan > p{
    font-size: 1.3rem;
  }
  .sidebar{
    display: none;
  }
  .contact--me{
    font-size: 2rem;
  }
  .filter{
   display: flex;
  }
  .footer{
    width: 100%;
    padding: 0;
  }
  .footer p{
    margin: 10px;
  }
  .foot--content{
    display: block;
  }
 }


